// src/App.js
import React from 'react';
import MainPage from './MainPage';
function App() {
    return (
        <>        
        <div className="flex items-center justify-center bg-yurt-background">
            <MainPage />  
        </div>
        </>
    );
}

export default App;
