// src/CityComboBox.js
import React, { useState, useEffect,useRef } from 'react';
import Footer from "./components/Footer/Footer"
const MainPage = () => {
    const cities = ['İstanbul', 'Ankara','Adana','Eskişehir','İzmir','Sakarya','Gaziantep','Kırıkkale','Kahramanmaraş','Çanakkale','Isparta','Karabük','Kütahya','Trabzon','Kocaeli','Sivas'];
    const [selectedCity, setSelectedCity] = useState('İstanbul'); // Default to Ankara
    const [menuData, setMenuData] = useState(null);
    const [dayType, setDayType] = useState('today'); // Default to today

    const [isOpen, setIsOpen] = useState(false);
    //yeni ekledim 
    const comboBoxRef = useRef(null);
    

    const handleInputFocus = () => {
        setIsOpen(true);
    };
    const handleCitySelect = (city) => {
        setSelectedCity(city);
        setIsOpen(false);
    };

    // yeni ekledim 
    const handleOutsideClick = (event) => {
        // Eğer tıklama combobox alanının dışında ise kapat
        if (comboBoxRef.current && !comboBoxRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
   
    // yeni ekledim 
    useEffect(() => {
        // "mousedown" olayını dinle
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            // Component unmount olduğunda dinleyiciyi kaldır
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    

    useEffect(() => {
        fetch('/data.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setMenuData(data[selectedCity]);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [selectedCity]);
   
    const getCurrentDay = () => {
        const today = new Date();
        return today.getDate(); // Get current day of the month (1-31)
    };

    const getTomorrowDay = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1); // Increment by one day
        console.log(tomorrow)
        return tomorrow.getDate(); // Get tomorrow's day of the month (1-31)
    };

    const getCurrentMonth = () => {
        const today = new Date();
        const monthNames = [
            "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
            "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ];
        return monthNames[today.getMonth()]; // Get month name
    };   

    const renderMenu = () => {
        if (!menuData) return null;
        let meals;
        if (dayType === 'today') {
            meals = menuData.this_month[getCurrentDay()];
        } else if (dayType === 'tomorrow') {
            meals = menuData.this_month[getTomorrowDay()];
        }
        return (
            <div className="mt-1 p-1 bg-yurt-background">
                {/* Title  */}
                {/* <h2 className="text-lg font-bold">{selectedCity} - {dayType.charAt(0).toUpperCase() + dayType.slice(1)} Menüsü</h2> */}
                <h2 className="mb-1 text-xl  text-center font-bold">
                    {/* {selectedCity} - {dayType === 'today' ? 'Bugün' : 'Yarın'} Menüsü */}
                    {dayType === 'today' ? 'Bugün' : 'Yarın'}

                </h2>

                {/* Grid Area  */}
                <div className=" grid grid-cols-2 gap-2 ">
                    {/* Left Breakfast  */}
                    <div className=" col-span-1  bg-yurt-white rounded-xl ">
                        {/* Kahvaltı barı  */}
                        <div className=" rounded-xl">
                            <div className=" shadow-dark text-xl text-center mb-4  bg-yurt-kahvalti rounded-xl flex justify-left items-center  ">
                                <h3 className="pl-4 italic text-white p-1">Kahvaltı</h3>
                            </div>
                            
                            <ul className='font-bold'>
                                {meals.breakfast.map((item) => (
                                   
                                    <li className='ml-1 mb-3 text-xs' key={item}>
                                        {item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* Right Dinner  */}
                    <div className="col-span-1 bg-yurt-white rounded-xl">
                        {/* Akşam barı  */}
                        <div className=" rounded-xl">
                            <div className="shadow-dark text-xl text-center mb-4  bg-yurt-aksam rounded-xl flex justify-left items-center  ">
                                <h3 className="pl-4 italic text-white p-1">Akşam</h3>
                            </div>
                            <ul className='font-bold'>
                                {meals.dinner.map((item) => (
                                    <li className='ml-1 mb-3 text-xs' key={item}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <section className="mt-1 p-1 z-10 bg-yurt-background md:w-1/2">
                {/* Header Section Begin  */}
                <header className="sticky top-0 bg-yurt-background">
                    {/* Header Container  */}
{/* burada test ediyrum  */}

        {/* burada test ediyorum  */}
                    <div className="container  flex flex-col items-center justify-center">
                        <h2 className="mb-5 mt-3 text-black font-black text-3xl">YurtYemek<span className="font-normal text-2xl">.com</span></h2>
                        <div className="bg-yurt-white text-black group rounded-xl p-2 flex items-center justify-between w-full h-15">
                            <a
                                onClick={() => setDayType('today')}
                                className={`text-custom-xs hover:scale-110 hover:bg-yurt-hover-renk hover:rounded-xl hover:text-white   ml-6 py-2 px-3 ${dayType === 'today' ? 'bg-yurt-hover-renk text-white rounded-xl  shadow-dark ' : ''}`}
                                href="#"
                            >
                                Bugün
                            </a>
                            <a
                                onClick={() => setDayType('tomorrow')}
                                className={`text-custom-xs hover:scale-110 hover:bg-yurt-hover-renk hover:rounded-xl hover:text-white    py-2 px-3 ${dayType === 'tomorrow' ? 'bg-yurt-hover-renk text-white rounded-xl  shadow-dark' : ''}`}
                                href="#"
                            >
                                Yarın
                            </a>
                            <a
                                onClick={() => setDayType('this_month')}
                                className={`text-custom-xs  hover:scale-110 hover:bg-yurt-hover-renk hover:rounded-xl hover:text-white  mr-6 py-2 px-3 ${dayType === 'this_month' ? 'bg-yurt-hover-renk text-white rounded-xl shadow-dark' : ''}`}
                                href="#"
                            >
                                Bu Ay
                            </a>
                        </div>
                        <div ref={comboBoxRef} className="relative mt-3 mb-3 w-1/2">
                            <div className="flex items-center border bg-yurt-white text-yurt-combobox-renk shadow-gray-500 shadow-2xl rounded-xl">
                                <input 
                                    type="text"
                                    value={selectedCity}
                                    readOnly
                                    onFocus={handleInputFocus}
                                    className=" rounded-lg w-full p-2 text-center bg-transparent focus:outline-none cursor-pointer font-bold"
                                    placeholder="Şehir Seçin"
                                />
                                <div className="p-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-gray-600 cursor-pointer"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                            </div>
                            {isOpen && (
                                <div className="absolute z-10 w-full mt-1 bg-gray-100 border border-gray-400 rounded-md shadow-xl transition-all duration-200">
                                    {cities.map((city, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleCitySelect(city)}
                                            className="text-xs cursor-pointer p-2 hover:bg-gray-300 hover:text-gray-800 transition-colors duration-150 font-bold"
                                        >
                                            {city}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </header>
                {/* Header Section End  */}

                {/* Main Section Begin  */}

                {dayType !== 'this_month' && renderMenu()}
                {dayType === 'this_month' && menuData && (

                    <div className="mt-1 p-0 bg-yurt-background ">
                        {/* deneme  */}
                        <div className="mt-1 p-0 rounded">
                            {Object.keys(menuData.this_month).map(day => (
                                <div key={day} className="mt-1 p-1 rounded">
                                    <h2 className="mb-1 text-xl text-center font-bold">{day} {getCurrentMonth()}</h2>
                                    <div className="grid grid-cols-2 gap-2 ">
                                        {/* Left Breakfast  */}
                                        <div className="col-span-1 bg-yurt-white rounded-xl ">
                                             {/* Kahvaltı barı  */}
                                            <div className="rounded-xl ">
                                                <div className="shadow-dark text-xl  text-center mb-4  bg-yurt-kahvalti rounded-xl flex justify-left items-center">
                                                    <h3 className="pl-4 italic text-white p-1 ">Kahvaltı</h3>
                                                    {/* <h4 className="font-semibold">Kahvaltı:</h4> */}
                                                </div>
                                                <ul className='font-bold'>
                                                    {menuData.this_month[day].breakfast.map((item) => (
                                                        <li className=' ml-1 mb-3 text-xs' key={item}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Right Dinner  */}
                                        <div className="col-span-1  bg-yurt-white rounded-xl">
                                            {/* Akşam barı  */}
                                            <div className=" rounded-xl  ">
                                                <div className="shadow-dark text-xl  text-center mb-4 bg-yurt-aksam rounded-xl flex justify-left items-center">
                                                    {/* <MdDinnerDining /> */}
                                                    <h3 className="pl-4 italic text-white p-1">Akşam</h3>
                                                </div>
                                                {/* <h4 className="font-semibold">Akşam Yemeği:</h4> */}
                                                <ul className='font-bold'>
                                                    {menuData.this_month[day].dinner.map((item) => (
                                                        <li className=' ml-1 mb-3 text-xs' key={item}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                        {/* deneme  */}
                    </div>
                )}

                {/* Main Section End  */}
                <Footer />
            </section >

        </>
    );
};

export default MainPage;
